// Change this variable to DEV or PROD
const current_env = "DEV";
let ENV = {}

// PROD variables
if (current_env === "PROD") {
    ENV = {
        BASE_URL: "https://api.drivetlc.com",
        BASE_UI_URL: "https://www.drivetlc.com",
    };
}

// DEV variables
if (current_env === "DEV") {
    ENV = {
        BASE_URL: "https://dev-api.drivetlc.com",
        BASE_UI_URL: "https://www.dev.drivetlc.com",
    };
}

// Email Sender URL
ENV['BASE_EMAIL_URL'] = "https://f32zom1531.execute-api.us-east-1.amazonaws.com/prod"

// Cognito information
ENV['COGNITO_GOOGLE_CLIENT_ID'] = "i5ekthjvudk95lmql6li4gjce"
ENV['COGNITO_GOOGLE_CLIENT_SECRET'] = "k3i3ts900976b9lcj9r8sna4autonmfl9kn1kc5084pvj12d1hn"
ENV['COGNITO_FACEBOOK_CLIENT_ID'] = "4r2cvilvcqa2kbsis97r5ej7fu"
ENV['COGNITO_FACEBOOK_CLIENT_SECRET'] = "8p770kfkoi6ged2aq2fooh7oo83ijq0gaekqdooqg1ivcq2ntbc"

export default ENV;
