import React, { useEffect, useState, useContext, useRef } from "react";
import classes from "./Cart.module.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { v1 as uuidv1 } from "uuid";
import { useSelector, useDispatch } from "react-redux";

import ENV from "../../env";

import { AccountContext } from "../auth/account";
import Spinner from "../Spinner";
import OrderList from "./OrderList";
import ShippingDetails from "./ShippingDetails";
import CartSummary from "./CartSummary";
import { setCartProductCounter } from "./../../redux/slices/cartSlice";

const Cart = () => {
  const { getSession } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(true); // Loading page status
  const [isError, setIsError] = useState(false); // If eny error
  const [errorMsg, setErrorMsg] = useState([]); // Error message
  const [selectedPM, setSelectedPM] = useState(""); // User selected payment method
  const [isSubmit, setIsSubmit] = useState(false); // Payment processing status
  const [paymentSubmited, setPaymentSubmited] = useState(false); // Payment successfully processed
  const [paymentAmount, setPaymentAmount] = useState(0); // Total user payment dollar amount in stripe format for transaction
  const [userOrderList, setUserOrderList] = useState([]); // user Order List, all cart objs
  const [shippingDetails, setShippingDetails] = useState({}); // User shipping details obj
  const [selectedOrders, setSelectedOrders] = useState([]); // User selected orders
  const productTitlesForEmail = useRef([]); // List of products title for email confirmation

  // Redux
  const cartProductCount = useSelector((state) => state.cart.cartProductCount);
  const shippingPrice = useSelector((state) => state.cart.shippingPrice);
  const dispatch = useDispatch();

  // Get all product titles for email confirmation
  useEffect(() => {
    if (selectedOrders.length > 0) {
      productTitlesForEmail.current = [
        ...new Set(
          userOrderList
            .filter((order) => {
              return selectedOrders.includes(order.id);
            })
            .map((order) => {
              return order.title;
            })
        ),
      ];
    }
    // eslint-disable-next-line
  }, [selectedOrders]);

  useEffect(() => {
    // Make API call and do payment
    getSession()
      .then((session) => {
        axios
          .get(ENV.BASE_URL + "/users/cart", {
            headers: {
              Authorization: `Bearer ${session.idToken.jwtToken}`,
            },
          })
          .then((response) => {
            // Check if request is success
            if (response.status === 200) {
              setUserOrderList(response.data);
              setIsLoading(false);
            } else {
              console.log("[ERROR]: Error happens while fetching user info.");
              setIsLoading(false);
            }
          });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errorMsg.length) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [errorMsg]);

  function sendConfirmationEmail() {
    // Get user email
    getSession().then((session) => {
      axios
        .get(ENV.BASE_URL + "/users", {
          headers: { Authorization: `Bearer ${session.idToken.jwtToken}` },
        })
        .then((response) => {
          // Check if request is success
          if (response.status === 200) {
            console.log("Sending confirmation email ...");
            const today = new Date(Date.now());

            axios.post(ENV.BASE_EMAIL_URL + "/emails/send-email", {
              template_name: "payment_order_confirmation.html",
              data: {
                date: today.toLocaleDateString(),
                items: productTitlesForEmail.current.join(", "),
                subtotal: (paymentAmount - shippingPrice).toFixed(2),
                shipping: shippingPrice,
                order_total: paymentAmount,
              },
              subject: "Order confirmation",
              recipients: [response.data.email],
            });
          }
        });
    });
  }

  const onPayAndPost = () => {
    let shippingAddressStatus = false;

    // Check payment amoutn
    if (paymentAmount - shippingPrice <= 0) {
      if (!errorMsg.includes("Please select product to buy")) {
        setErrorMsg((errorMsg) => [
          ...errorMsg,
          "Please select product to buy",
        ]);
      }
    } else {
      setErrorMsg((errorMsg) =>
        errorMsg.filter((element) => element !== "Please select product to buy")
      );
    }
    // Check if pm selected
    if (!selectedPM) {
      if (!errorMsg.includes("Payment method not selected")) {
        setErrorMsg((errorMsg) => [...errorMsg, "Payment method not selected"]);
      }
    } else {
      setErrorMsg((errorMsg) =>
        errorMsg.filter((element) => element !== "Payment method not selected")
      );
    }

    // Check Shipping details
    if (
      !shippingDetails?.full_name ||
      !shippingDetails?.address_one ||
      !shippingDetails?.address_city ||
      !shippingDetails?.address_state ||
      !shippingDetails?.address_zip
    ) {
      if (!errorMsg.includes("Please fill out Shipping Details")) {
        setErrorMsg((errorMsg) => [
          ...errorMsg,
          "Please fill out Shipping Details",
        ]);
      }
    } else {
      shippingAddressStatus = true;
      setErrorMsg((errorMsg) =>
        errorMsg.filter(
          (element) => element !== "Please fill out Shipping Details"
        )
      );
    }

    // Make API call to proccess payment
    if (
      paymentAmount - shippingPrice > 0 &&
      selectedPM &&
      shippingAddressStatus
    ) {
      const transactionId = uuidv1();

      // Make API call and do payment
      getSession()
        .then((session) => {
          setIsSubmit(true);
          axios
            .post(
              ENV.BASE_URL + "/payments/create-payment-intent",
              {
                "user-pm": selectedPM,
                "payment-amount": parseInt(paymentAmount * 100),
                "transaction-id": transactionId,
              },
              {
                headers: {
                  Authorization: `Bearer ${session.idToken.jwtToken}`,
                },
              }
            )
            .then((response) => {
              // Check if request is success
              if (response.status === 200) {
                // Send Confirmation email
                sendConfirmationEmail();

                // Save user transaction
                axios
                  .post(
                    ENV.BASE_URL + "/users/transactions",
                    {
                      "transaction-id": transactionId,
                      "cart-ids": selectedOrders.toString(),
                      "delivery-address": {
                        full_name: shippingDetails.full_name,
                        address_one: shippingDetails.address_one,
                        address_two: shippingDetails.address_two,
                        address_city: shippingDetails.address_city,
                        address_state: shippingDetails.address_state,
                        address_zip: shippingDetails.address_zip,
                      },
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${session.idToken.jwtToken}`,
                      },
                    }
                  )
                  .then(() => {
                    // Change cart products status to COMPLEATED
                    for (let i = 0; i < selectedOrders.length; i++) {
                      axios
                        .patch(
                          ENV.BASE_URL + "/users/cart",
                          {
                            "cart-product-id": selectedOrders[i],
                            status: "COMPLEATED",
                          },
                          {
                            headers: {
                              Authorization: `Bearer ${session.idToken.jwtToken}`,
                            },
                          }
                        )
                        .then(() => {
                          // Remove products from cart icon
                          var tempObj = userOrderList.filter((obj) => {
                            return obj.id === selectedOrders[i];
                          });

                          dispatch(
                            setCartProductCounter(
                              cartProductCount - tempObj[0].quantity
                            )
                          );
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });

                setIsSubmit(false);
                setPaymentSubmited(true);
              } else {
                console.log("[ERROR]: Error happens while fetching user info.");
                setIsSubmit(false);
              }
            });
        })
        .catch((err) => {
          console.log(err);
          setIsSubmit(false);
        });
    }
  };

  return (
    <>
      {paymentSubmited && (
        <div className={classes.container}>
          <p className={classes.title}>Thank you for your order</p>
          <p className={classes.success}>
            We will process and send your order shortly
          </p>
          <div className={classes.submitButtonBlock}>
            <Link to={`/`} className={classes.button}>
              Go to main page
            </Link>
          </div>
        </div>
      )}

      {!paymentSubmited && (
        <div className={classes.container}>
          <h1 className={classes.title}>Review & Checkout</h1>

          {isLoading && <Spinner />}

          {!isLoading && (
            <div className={classes.mainSection}>
              <div className={classes.main}>
                {/* <ListingPreview listingId={urlState.listingId} /> */}
                <div className={classes.main__orderList}>
                  <OrderList
                    orderList={userOrderList}
                    setOrderList={setUserOrderList}
                    selectedOrders={selectedOrders}
                    setSelectedOrders={setSelectedOrders}
                  />
                </div>
                <ShippingDetails
                  setShippingDetails={(shippingObj) => {
                    setShippingDetails(shippingObj);
                  }}
                />
              </div>

              <div className={classes.summary}>
                <CartSummary
                  setSelectedPM={(pm) => setSelectedPM(pm)}
                  setPaymentAmount={(pa) => setPaymentAmount(parseFloat(pa))}
                />
              </div>
            </div>
          )}

          {/* Error block */}
          <div
            className={classes.errorBlock}
            style={{ display: isError ? "block" : "none" }}
          >
            <ul>
              {errorMsg.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>

          {/* Submit */}
          {!isSubmit && (
            <div className={classes.submitButtonBlock}>
              <button className={classes.button} onClick={onPayAndPost}>
                Pay and Submit
              </button>
            </div>
          )}

          {isSubmit && (
            <p
              style={{
                justifyContent: "center",
                display: "flex",
                margin: "1rem",
              }}
            >
              Loading...
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default Cart;
